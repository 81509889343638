import axios from "@/libs/api.request";

export const login = (reqdata) => {
  return axios.request({
    url: "/auth/login",
    data: reqdata,
    method: "post",
  });
};

export const logout = () => {
  return axios.request({
    url: "/auth/logout",
    method: "get",
  });
};

export const getUserInfo = () => {
  return axios.request({
    url: "/auth/user_info",
    method: "get",
  });
};

export const email_send_login = (query) => {
  return axios.request({
    url: "/auth/email_login",
    method: "get",
    params: query,
  });
};

export const email_login = (reqdata) => {
  return axios.request({
    url: "/auth/email_login",
    data: reqdata,
    method: "post",
  });
};

export const register_judge = (query) => {
  return axios.request({
    url: "/auth/register",
    method: "get",
    params: query,
  });
};

export const register = (reqdata) => {
  return axios.request({
    url: "/auth/register",
    method: "post",
    data: reqdata,
  });
};

export const userHome = () => {
  return axios.request({
    url: "/auth/user_home",
    method: "get",
  });
};

export const changeUserInfo = (reqdata) => {
  return axios.request({
    url: "/auth/user_home",
    method: "post",
    data: reqdata,
  });
};
