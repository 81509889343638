import Vue from "vue";
import VueRouter from "vue-router";
import "nprogress/nprogress.css";
import Nprogress from "nprogress";
import { getToken } from "@/libs/utils";
import store from "@/store";
import { setToken } from "../libs/utils";
// import config from "@/config";

Vue.use(VueRouter);

const LOGIN_PAGE_NAME = [
  "/user",
  "/user/login",
  "/user/register",
  "/user/reset",
];

const routes = [
  {
    path: "/user",
    hideInMenu: true,
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/userLayout"),
    children: [
      {
        path: "/user",
        redirect: "/user/login",
      },
      {
        path: "/user/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/login"),
      },
      {
        path: "/user/register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/register"),
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/basicLayout"),
    children: [
      {
        path: "/daily_life",
        name: "daily_life",
        meta: {
          title: "日常生活",
          icon: "fire",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/daily_life/record",
            name: "daily_record",
            meta: {
              title: "日常记录",
              icon: "book",
            },
            component: () =>
              import(
                /* webpackChunkName: "daily_life" */ "../views/daily-life/daily_record"
              ),
          },
          {
            path: "/daily_life/edit",
            hideInMenu: true,
            name: "daily_edit",
            meta: {
              title: "记录编辑",
            },
            component: () =>
              import(
                /* webpackChunkName: "daily_edit" */ "../views/daily-life/daily_edit"
              ),
          },
          {
            path: "/daily_life/detail",
            hideInMenu: true,
            name: "deily_detail",
            meta: {
              title: "记录详情",
            },
            component: () =>
              import(
                /* webpackChunkName: "daily_detail" */ "../views/daily-life/daily_detail"
              ),
          },
          {
            path: "/daily_life/account",
            name: "daily_account",
            meta: {
              title: "鲨鱼记账",
              icon: "money-collect",
            },
            component: () =>
              import(
                /* webpackChunkName: "daily_account" */ "../views/daily-life/daily_account"
              ),
          },
          {
            path: "/daily_life/icon_library",
            name: "icon_library",
            meta: {
              title: "图标库",
              icon: "setting",
            },
            component: () =>
              import(
                /* webpackChunkName: "icon_library" */ "../views/daily-life/icon_library"
              ),
          },
        ],
      },
      {
        path: "/wiki",
        name: "wiki",
        meta: {
          title: "知识库管理",
          icon: "zhihu",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/wiki/tag",
            name: "tag",
            meta: {
              title: "标签管理",
              icon: "tags",
            },
            component: () =>
              import(/* webpackChunkName: "tag" */ "../views/wiki/tag"),
          },
          {
            path: "/wiki/catlog",
            name: "catalog",
            meta: {
              title: "目录管理",
              icon: "folder-open",
            },
            component: () =>
              import(/* webpackChunkName: "wiki" */ "../views/wiki/catalog"),
          },
          {
            path: "/wiki/vul",
            name: "vul_wiki",
            meta: {
              title: "漏洞知识库",
              icon: "bug",
            },
            component: () =>
              import(/* webpackChunkName: "wiki" */ "../views/wiki/vul_wiki"),
          },
          {
            path: "/wiki/edit",
            name: "wiki_edit",
            hideInMenu: true,
            meta: {
              title: "知识库编辑",
            },
            component: () =>
              import(/* webpackChunkName: "wiki" */ "../views/wiki/wiki_edit"),
          },
          {
            path: "/wiki/display",
            name: "wiki_display",
            hideInMenu: true,
            meta: {
              title: "知识库",
            },
            component: () =>
              import(
                /* webpackChunkName: "wiki" */ "../views/wiki/wiki_display"
              ),
          },
        ],
      },
      {
        path: "/information",
        name: "information",
        meta: {
          title: "信息搜集",
          icon: "search",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/information/src",
            name: "src",
            meta: {
              title: "SRC",
              icon: "security-scan",
            },
            component: () =>
              import(
                /* webpackChunkName: "information" */ "../views/information/src"
              ),
          },
          {
            path: "/information/domain",
            name: "domain",
            meta: {
              title: "域名信息",
              icon: "eye",
            },
            component: () =>
              import(
                /* webpackChunkName: "domain" */ "../views/information/domain"
              ),
          },
          {
            path: "/information/sub_domain",
            name: "sub_domain",
            meta: {
              title: "子域名信息",
              icon: "eye",
            },
            component: () =>
              import(
                /* webpackChunkName: "sub_domain" */ "../views/information/sub_domain"
              ),
          },
        ],
      },
      {
        path: "/plugin",
        name: "plugin",
        meta: {
          title: "插件管理",
          icon: "api",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/plugin/list",
            name: "plugin_list",
            meta: {
              title: "插件列表",
              icon: "unordered-list",
            },
            component: () =>
              import(
                /* webpackChunkName: "plugin_list" */ "../views/plugin/list"
              ),
          },
        ],
      },
      {
        path: "/task",
        name: "task",
        meta: {
          title: "任务管理",
          icon: "pie-chart",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/task/list",
            name: "task_list",
            meta: {
              title: "任务列表",
              icon: "hdd",
            },
            component: () =>
              import(/* webpackChunkName: "task_list" */ "../views/task/task"),
          },
          {
            path: "/task/detail",
            name: "task_detail",
            meta: {
              title: "任务详情",
              icon: "build",
            },
            component: () =>
              import(
                /* webpackChunkName: "task_detail" */ "../views/task/detail"
              ),
          },
        ],
      },
      {
        path: "/web3",
        name: "Web3",
        meta: {
          title: "Web3",
          icon: "block",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/web3/ether",
            name: "ether",
            meta: {
              title: "以太坊",
              icon: "strikethrough",
            },
            component: () =>
              import(/* webpackChunkName: "encode" */ "../views/web3/ether"),
          },
        ],
      },
      {
        path: "/tool",
        name: "tool",
        meta: {
          title: "小工具",
          icon: "tool",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/tool/encode",
            name: "encode",
            meta: {
              title: "编码",
              icon: "disconnect",
            },
            component: () =>
              import(/* webpackChunkName: "encode" */ "../views/tool/encode"),
          },
          {
            path: "/tool/encrypt",
            name: "encrypt",
            meta: {
              title: "加密",
              icon: "key",
            },
            component: () =>
              import(/* webpackChunkName: "key" */ "../views/tool/encrypt"),
          },
        ],
      },
      {
        path: "/user_manage",
        name: "user_manage",
        meta: {
          title: "系统管理",
          icon: "setting",
        },
        component: { render: (h) => h("router-view") },
        children: [
          {
            path: "/user_manage/user",
            name: "user_auth",
            meta: {
              title: "用户管理",
            },
            component: () =>
              import(
                /* webpackChunkName: "user_manage" */ "../views/auth/user"
              ),
          },
          {
            path: "/user_manage/user_home",
            name: "user_home",
            hideInMenu: true,
            meta: {
              title: "个人中心",
            },
            component: () =>
              import(
                /* webpackChunkName: "user_manage" */ "../views/auth/user_home"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/403",
    name: "error_403",
    hideInMenu: true,
    component: () =>
      import(/* webpackChunkName: "error_page" */ "../views/error-page/403"),
  },
  {
    path: "/500",
    name: "error_500",
    hideInMenu: true,
    component: () =>
      import(/* webpackChunkName: "error_page" */ "../views/error-page/500"),
  },
  {
    path: "*",
    name: "error_404",
    hideInMenu: true,
    component: () =>
      import(/* webpackChunkName: "error_page" */ "../views/error-page/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Nprogress.start();
  const token = getToken();
  if (!token && LOGIN_PAGE_NAME.indexOf(to.path) === -1) {
    // 未登录且要跳转的页面不是登录页
    router.push("/user/login");
    next();
  } else if (!token && LOGIN_PAGE_NAME.indexOf(to.path) !== -1) {
    // 未登录且要跳转的页面是登录页
    next();
  } else {
    if (store.state.user.hasGetInfo) {
      next();
    } else {
      store
        .dispatch("getUserInfo")
        .then(() => {
          next();
        })
        .catch(() => {
          setToken("");
          next({ path: "/user" });
        });
    }
  }
});

router.afterEach(() => {
  Nprogress.done();
});

export default router;
