import { login, logout, getUserInfo, email_login } from "@/api/auth";
import { setToken, getToken } from "../../libs/utils";

export default {
  state: {
    userName: "",
    userId: "",
    token: getToken(),
    access: [],
    hasGetInfo: false,
    isSuperuser: false,
  },
  mutations: {
    setUserName(state, name) {
      state.userName = name;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setAccess(state, access) {
      state.access = access;
    },
    setToken(state, token) {
      state.token = token;
      setToken(token);
    },
    setSuperuser(state, is_superuser) {
      state.isSuperuser = is_superuser;
    },
    setHasGetInfo(state, status) {
      state.hasGetInfo = status;
    },
  },
  actions: {
    // 登录
    handleLogin({ commit }, { data }) {
      return new Promise((resolve, reject) => {
        login(data)
          .then((res) => {
            const data = res.data;
            if (data.token) {
              commit("setToken", data.token);
              commit("setUserId", data.id);
              commit("setUserName", data.username);
              commit("setAccess", data.access);
              commit("setHasGetInfo", true);
              commit("setSuperuser", data.is_superuser);
              resolve(data);
            } else {
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 邮箱登录
    emailLogin({ commit }, { data }) {
      return new Promise((resolve, reject) => {
        email_login(data)
          .then((res) => {
            const data = res.data;
            if (data.token) {
              commit("setToken", data.token);
              commit("setUserId", data.id);
              commit("setUserName", data.username);
              commit("setAccess", data.access);
              commit("setHasGetInfo", true);
              commit("setSuperuser", data.is_superuser);
              resolve(data);
            } else {
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 退出登录
    handleLogout({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit("setToken", "");
            commit("setAccess", []);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取用户相关信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data;
            commit("setToken", data.token);
            commit("setUserId", data.id);
            commit("setUserName", data.username);
            commit("setAccess", data.access);
            commit("setHasGetInfo", true);
            commit("setSuperuser", data.is_superuser);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
