import Cookies from "js-cookie";
// cookie保存的天数
import config from "@/config";
const { cookieExpires } = config;

export const TOKEN_KEY = "token";

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires || 1 });
};

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  if (token) return token;
  return false;
};

export const changeTime = (time) => {
  let new_time = time.replace("T", " ");
  return new_time.split(".")[0];
};

export const showList = (data) => {
  // 判断是列表还是字符串
  if (data instanceof String) {
    return data;
  } else if (data instanceof Array) {
    return data.join("<br/>");
  }
};
