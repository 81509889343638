import axios from "axios";
import store from "@/store";
import router from "@/router";

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
    };
    return config;
  }
  destory(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 判断token是否存在
        if (store.state.user.token) {
          // 将token设置成请求头
          config.headers.Authorization = "Token " + store.state.user.token;
        }
        this.queue[url] = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        this.destory(url);
        const { data, status } = res;
        return { data, status };
      },
      (error) => {
        this.destory(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url },
          };
        } else {
          if (errorInfo.status === 403) {
            router.push({
              name: "error_403",
            });
          }
        }
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    const instance = axios.create();
    // Object.assign, 用于将所有可枚举属性的值从一个或多个源对象分配到目标对象, 它将返回目标对象
    // 示例:
    // const target = { a: 1, b: 2 };
    // const source = { b: 4, c: 5 };
    // const returnedTarget = Object.assign(target, source);
    // console.log(target);
    // expected output: Object { a: 1, b: 4, c: 5}
    // console.log(returnedTarget);
    // expected output: Object { a: 1, b: 4, c: 5}
    // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

export default HttpRequest;
