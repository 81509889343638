import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import {
  Button,
  Layout,
  Icon,
  Menu,
  Radio,
  Form,
  Input,
  Row,
  Col,
  Space,
  Alert,
  message,
  Dropdown,
  Modal,
  Card,
  Table,
  LocaleProvider,
  ConfigProvider,
  Tag,
  Select,
  Spin,
  FormModel,
  BackTop,
  Tabs,
  Drawer,
  InputNumber,
  DatePicker,
  TimePicker,
  Tree,
  Cascader,
  List,
  Switch,
  Checkbox,
  Upload,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Icon);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Radio);
Vue.use(Form);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Space);
Vue.use(Alert);
Vue.use(Dropdown);
Vue.use(Modal);
Vue.use(Card);
Vue.use(Table);
Vue.use(LocaleProvider);
Vue.use(ConfigProvider);
Vue.use(Tag);
Vue.use(Spin);
Vue.use(Select);
Vue.use(FormModel);
Vue.use(BackTop);
Vue.use(Tabs);
Vue.use(Drawer);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Tree);
Vue.use(Cascader);
Vue.use(List);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Upload);

Vue.prototype.$moment = moment;
Vue.prototype.$message = message;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
